console.log('此项目已在Github上开源：https://github.com/mcmyth/home-page')
export default {
  beian: '粤ICP备2023154975号-1',
  buttonList: [
    {
      img: '/assets/icon/qq.svg',
      title: 'QQ',
      link: ''
    },
    {
      img: '/assets/icon/github.svg',
      title: 'Github',
      link: 'https://github.com/linrongan'
    },
    {
      img: '/assets/icon/codepen.svg',
      title: 'Codepen',
      link: ''
    },
    {
      title: '博客',
      text: 'Blog',
      link: 'https://blog.csdn.net/qq_41931764?type=blog'
    },
    {
      title: '小工具',
      text: '小工具',
      link: '/tool',
      _blank: false
    },
    {
      title: 'xiaoshuo',
      text: '小说漫画',
      link: 'https://mh.phper.top'
    }
  ],
  friendLink: [

  ]
}
